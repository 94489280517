export class Hub {
  id: number;
  UserId: number;
  serialNumber: string;
  lastestHeartbeatDateTime: any;
  dateStart: any;
  dateEnd: any;
  createdAt: any;
  updatedAt: any;
  kit: boolean;
  CustomerId: string;
  HealthCareProviderId: string;
  debug: boolean;
  Silog: string;
  authorizedDevices: any;
  graceNumber: number;
  shippingDate: any;
  factoryReset: boolean;
  latestFactoryResetDate: any;
  resetAutoKitting: string;
  resetPerDevices: string;
  customerHasChanged: boolean;
  firmwareStatus: string;

  /**
   * Constructor
   *
   * @param hub
   */
  constructor(hub?) {
    hub = hub || {};
    this.UserId = hub.UserId || null;
    this.serialNumber = hub.serialNumber || '';
    this.lastestHeartbeatDateTime = hub.lastestHeartbeatDateTime || new Date();
    this.dateStart = hub.dateStart || new Date();
    this.dateEnd = hub.dateEnd || null;
    this.kit = hub.kit || false;
    this.CustomerId = hub.CustomerId || null; // default edevice customer id
    this.HealthCareProviderId = hub.HealthCareProviderId || null;
    this.debug = hub.debug || false;
    this.Silog = hub.Silog || '';
    this.authorizedDevices = hub.authorizedDevices || '';
    this.graceNumber = hub.graceNumber || 0;
    this.shippingDate = hub.shippingDate || null;
    this.factoryReset = hub.factoryReset || false;
    this.latestFactoryResetDate = hub.latestFactoryResetDate || null;
    this.resetAutoKitting = hub.resetAutoKitting || null;
    this.resetPerDevices = hub.resetPerDevices || null;
    this.customerHasChanged = hub.customerHasChanged || null;
    this.firmwareStatus = hub.firmwareStatus || '';
  }
}
