// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  hmr: false,
  apiUrl: 'https://ccabackend.edevice.com/',
  appUrl: 'https://cca.edevice.com',
  logUrl: 'https://04e8x24wbg.execute-api.us-east-1.amazonaws.com/production/',
  env: 'CCA',
  roleEdevice: 1,
  roleCustomer: 2,
  roleHealthCareProvider: 3,
  rolePhysician: 4,
  roleKitManager: 5,
  rolePatient: 6,
  roleGuest: 7,
  roleApi: 8,
  severityHard: 4,
  severityMedium: 3,
  severityOk: 2,
  severityNotDefined: 1,
  twofactor: false,
  action_out_of_norm: 1,
  action_transmission_missing: 2,
  action_touch_time: 3,
  action_medical_staff: 4,
  action_normal: 5,
  action_status_new: 1,
  action_status_inprogress: 2,
  action_status_done: 3,
  action_status_canceled: 4,
  title: 'edevice CCA PreProd'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
